import React from 'react';

export default function Knowledge(){
    return (
        <svg 
        style={{ height: '450px'}}
        xmlns="http://www.w3.org/2000/svg" 
        data-name="Layer 1" 
        width="538.71458" 
        height="578.41033" 
        viewBox="0 0 538.71458 578.41033" 
        xlink="http://www.w3.org/1999/xlink"
        >
        <path 
        d="M837.41028,600.84439H432.47468a13.6248,13.6248,0,0,1-13.609-13.609V257.51245a13.6248,13.6248,0,0,1,13.609-13.609h404.9356a13.6248,13.6248,0,0,1,13.609,13.609V587.23536A13.6248,13.6248,0,0,1,837.41028,600.84439Z" 
        transform="translate(-330.64271 -160.79484)" 
        fill="#e6e6e6"/>
        <path 
        d="M798.0658,591.66492H441.65635a13.62481,13.62481,0,0,1-13.609-13.609V266.69924a13.6248,13.6248,0,0,1,13.609-13.609H828.2308a13.6248,13.6248,0,0,1,13.609,13.609V547.89088A43.82337,43.82337,0,0,1,798.0658,591.66492Z" 
        transform="translate(-330.64271 -160.79484)" 
        fill="#fff"/>
        <path 
        d="M700.12518,323.49323H537.372a3.50978,3.50978,0,1,1,0-7.01955H700.12518a3.50978,3.50978,0,0,1,0,7.01955Z" 
        transform="translate(-330.64271 -160.79484)" fill="#6c63ff"
        />
        <path 
        d="M782.51373,360.94029H537.372a3.50978,3.50978,0,1,1,0-7.01956H782.51373a3.50978,3.50978,0,0,1,0,7.01956Z" 
        transform="translate(-330.64271 -160.79484)" 
        fill="#e6e6e6"/>
        <path 
        d="M782.51373,398.38734H537.372a3.50978,3.50978,0,1,1,0-7.01955H782.51373a3.50978,3.50978,0,0,1,0,7.01955Z" 
        transform="translate(-330.64271 -160.79484)" fill="#e6e6e6"
        />
        <path 
        d="M782.51373,435.8344H537.372a3.50978,3.50978,0,1,1,0-7.01956H782.51373a3.50978,3.50978,0,0,1,0,7.01956Z" 
        transform="translate(-330.64271 -160.79484)" 
        fill="#e6e6e6"
        />
        <path 
        d="M782.51373,473.28145H537.372a3.50978,3.50978,0,1,1,0-7.01956H782.51373a3.50978,3.50978,0,0,1,0,7.01956Z" 
        transform="translate(-330.64271 -160.79484)" 
        fill="#e6e6e6"
        />
        <path 
        d="M771.48987,532.95553a17.77195,17.77195,0,1,1,17.772-17.77194A17.772,17.772,0,0,1,771.48987,532.95553Z" 
        transform="translate(-330.64271 -160.79484)" 
        fill="#6c63ff"
        />
        <path 
        d="M781.488,514.33934l-5.03827-7.35387a1.4942,1.4942,0,1,0-2.46529,1.68906l3.25842,4.756H764.47748a1.75309,1.75309,0,1,0,0,3.50618h12.92573l-3.41874,4.99a1.4942,1.4942,0,0,0,2.46529,1.68905l5.03827-7.35386a1.97165,1.97165,0,0,0,0-1.92251Z" 
        transform="translate(-330.64271 -160.79484)" fill="#fff"
        />
        <path 
        d="M867.59008,195.279h-465.293a1.807,1.807,0,0,1,0-3.61318h465.293a1.807,1.807,0,0,1,0,3.61318Z" 
        transform="translate(-330.64271 -160.79484)" fill="#cacaca"
        />
        <ellipse 
        cx="102.57868" 
        cy="10.82345" 
        rx="10.58751" 
        ry="10.82345" 
        fill="#3f3d56"
        />
        <ellipse 
        cx="139.1537" 
        cy="10.82345" 
        rx="10.58751" 
        ry="10.82345" 
        fill="#3f3d56"
        />
        <ellipse 
        cx="175.72871" 
        cy="10.82345" 
        rx="10.58751" 
        ry="10.82345" 
        fill="#3f3d56"
        />
        <path 
        d="M845.56882,163.47754h-25.981a1.96761,1.96761,0,0,0,0,3.93445h25.981a1.96761,1.96761,0,0,0,0-3.93445Z" 
        transform="translate(-330.64271 -160.79484)" 
        fill="#3f3d56"
        />
        <path 
        d="M845.56882,170.86191h-25.981a1.96761,1.96761,0,0,0,0,3.93445h25.981a1.96761,1.96761,0,0,0,0-3.93445Z" 
        transform="translate(-330.64271 -160.79484)" fill="#3f3d56"
        />
        <path 
        d="M845.56882,178.23658h-25.981a1.96761,1.96761,0,0,0,0,3.93445h25.981a1.96761,1.96761,0,0,0,0-3.93445Z" 
        transform="translate(-330.64271 -160.79484)" 
        fill="#3f3d56"/><path d="M460.526,551.422a6.35157,6.35157,0,0,0,.00433-9.73935L456.67714,508.388l-12.23429,2.83278,6.799,31.56054A6.386,6.386,0,0,0,460.526,551.422Z" 
        transform="translate(-330.64271 -160.79484)" fill="#ffb6b6"
        />
        <path 
        d="M459.26183,513.817l-14.16389,8.8361s-.86214,5.17869-1.83611-7.8361c-.10167-1.35852.84623-3.72527.742-5.11966-.11754-1.5731-4.62334-2.2887-4.742-3.88034-.08449-1.13293,1.21813-2.14089,1.134-3.27075-.12742-1.71157-.254-3.41366-.37827-5.08825-.104-1.401-1.65571-2.289-1.75574-3.641-.10088-1.36348,1.25-3.19042,1.15477-4.48244-.71392-9.68847,1.75492-34.06335,1.75492-34.55923a7.07,7.07,0,0,0-1.50509-3.39169l.756-2.26112-.49991-4.24243a25.901,25.901,0,0,1,3.33931-16.06309h0l3.59716-2.35537,16.59769,27.91714C456.47219,478.53659,451.91287,488.02536,459.26183,513.817Z" 
        transform="translate(-330.64271 -160.79484)" fill="#6c63ff"
        />
        <polygon 
        points="117.567 561.177 126.135 561.177 130.213 528.128 117.567 528.128 117.567 561.177" 
        fill="#ffb6b6"
        />
        <path 
        d="M445.41381,716.1032l13.68858-.817V721.151l13.01411,8.988a3.66336,3.66336,0,0,1-2.08164,6.678l-16.29677,0-2.809-5.80117-1.09678,5.80117h-6.14453Z" 
        transform="translate(-330.64271 -160.79484)" 
        fill="#2f2e41"
        />
        <polygon 
        points="165.696 561.177 174.264 561.177 178.341 528.128 165.696 528.128 165.696 561.177" 
        fill="#ffb6b6"
        />
        <path 
        d="M493.54209,716.1032l13.68858-.817V721.151l13.01411,8.988a3.66336,3.66336,0,0,1-2.08164,6.678l-16.29677,0-2.809-5.80117L497.9606,736.817h-6.14453Z" 
        transform="translate(-330.64271 -160.79484)" 
        fill="#2f2e41"
        />
        <path 
        d="M444.57308,693.47223h.03333l1.64782-78.70485a14.05119,14.05119,0,0,1,.18436-13.27813l.23592-.42705-.6117-2.83547a18.061,18.061,0,0,1,1.03211-10.86978l1.53725-50.12523c-5.492-35.59255,20.68111-58.80467,20.68111-58.80467h39.20764l8.41743,28.92029c5.56465,54.21457,4.55495,132.01438-4.27537,183.1982l.24073.12173a2.03416,2.03416,0,0,1,.83061,2.68213c-.408,1.18214-1.44147,1.8979-2.30829,1.59868l-20.70613-.72019a2.034,2.034,0,0,1-.83061-2.68214c.408-1.18213,1.44147-1.89782,2.30829-1.59868l1.40789.7121-1.45266-2.95713a11.02944,11.02944,0,0,1,.89791-11.2364h0l-.25443-6.50975,0,0a9.91467,9.91467,0,0,1-1.94008-9.34289l1.39675-4.55886-1.65-42.217.33322-9.47122-.68647-10.74491-3.17387-48.33055-12.01356,59.433L462.791,694.07031a2.25046,2.25046,0,0,1,.69037,2.15445,2.33374,2.33374,0,0,1-2.31221,1.77615H444.51326a2.269,2.269,0,0,1-2.18512-2.87253A2.2968,2.2968,0,0,1,444.57308,693.47223Z" 
        transform="translate(-330.64271 -160.79484)" 
        fill="#2f2e41"/><path d="M539.85262,538.48449a6.35157,6.35157,0,0,0-1.84631-9.56274L527.897,496.9658l-11.47316,5.1058,12.672,29.69371a6.386,6.386,0,0,0,10.75684,6.71918Z" 
        transform="translate(-330.64271 -160.79484)" 
        fill="#ffb6b6"
        />
        <path 
        d="M498.25425,436.89321S509.784,477.69527,509.26183,481.817c-1.07688,8.49966-54.05972,34.62695-58,23l.49393-3.8218c-1.99061-3.98122,10.15422-19.7813,10.74376-21.84819.74909-2.64259-2.64259-11.31946-2.64259-11.31946s2.34435-7.92082,1.96978-12.82453c-.38147-4.90371-1.512-4.14772-5.65971-7.17178-4.14767-3.01711-10.938-16.97221-10.938-16.97221s3.01716-13.58057,3.01716-16.22316,12.39451-7.144,13.8996-8.64914a14.12947,14.12947,0,0,0,1.64381-2.82289c.30518-.61036.111-2.02532.437-2.72586.20807-.45775.93633-.17339,1.15139-.645.20807-.45776.02773-.98489.23579-1.43575.27052-.59648.9225-1.11667,1.17912-1.67848,1.17912-2.58015,2.1848-4.68176,2.49692-4.68176.74909,0,24.9,3.39863,24.9,3.39863a36.2218,36.2218,0,0,0,4.52224,5.6597c2.64259,2.63565,15.00933,9.05139,15.00933,9.05139Z" 
        transform="translate(-330.64271 -160.79484)" 
        fill="#6c63ff"
        />
        <path 
        d="M530.86537,505.5365c-1.88655,1.8866-11.222,10.54851-11.60354,8.28045-.37453-2.26112-.57841-2.53136-2.09044-3.28045-1.512-.756-1.512-1.13056-1.88655-3.77315-.37457-2.64259-3.77315-5.28518-3.39168-6.41574.37452-1.13056.02489.30769.02489-.82982,0-1.13056-1.13056-5.27823-2.64258-6.03427-1.50509-.756-3.77316-1.50508-2.63565-3.39168,1.13056-1.88655-10.64669-58.48381-10.64669-58.48381l10.56346-20.74536,7.16479-.756s2.17712,1.24573,2.85926,12.47653c.03454.56876.65479,1.61267.68119,2.23379.02194.51628-.54862.60151-.53291,1.1554q.005.17742.00962.35744c.01428.55061,1.501.95565,1.52329,1.48716.03986.94949.62384,7.00469.68677,7.89461q.10642,1.50492.25131,2.89923c1.04254,10.12072,1.41582,10.97568,2.06192,14.20616,1,5,5.1454,34.20228,5.1454,34.20228s3.01712.74909,3.01712,3.39168,3.32762,7.95386,1.82253,10.971C529.73481,504.40594,532.752,503.65,530.86537,505.5365Z" 
        transform="translate(-330.64271 -160.79484)" fill="#6c63ff"
        />
        <circle 
        cx="148.87448" 
        cy="212.53397" 
        r="17.89357" 
        fill="#ffb8b8"
        />
        <path 
        d="M440.9577,410.934q-5.67981-2.01294-11.35961-4.03319a11.43459,11.43459,0,0,1,2.4591-2.89432c3.60518-3.0104,8.58138-3.55445,13.15861-4.57,4.5845-1.01557,9.5607-3.09745,11.2364-7.47881,1.74092-4.5555-.78344-9.47365-2.24875-14.11619a28.443,28.443,0,0,1,.73992-19.91935,22.1715,22.1715,0,0,1,14.96488-12.7234c6.63008-1.47257,14.12341.92848,18.20012,6.35446a11.22233,11.22233,0,0,1,13.50684,6.2529,8.12532,8.12532,0,0,1-.43522,7.38451c-1.41453,2.13992-4.43941,3.23527-6.71716,2.04562l1.32023-3.35132c-4.084,1.38548-6.7389,8.77-6.18764,13.04983.54405,4.27259,3.03941,8.0156,5.57107,11.50475s5.23006,7.01455,6.31816,11.18556c1.56687,5.999-.55131,12.55658-4.46841,17.36595s-9.43739,8.07364-15.1535,10.47469A70.247,70.247,0,0,1,468.588,431.557c.81244-1.58136,1.61762-3.16271,2.43006-4.74407l-9.64048,5.83216c-1.86427.18135-3.74306.29017-5.6218.32643-5.46946.09431-11.0477-.47878-16.053-2.66218-5.01241-2.19071-9.4156-6.15864-11.22909-11.31618a14.666,14.666,0,0,1-.81235-5.1938Q434.30943,412.36306,440.9577,410.934Z" 
        transform="translate(-330.64271 -160.79484)" 
        fill="#2f2e41"
        />
        <path 
        d="M613.87529,738.18008H580.68877l-.14258-.25879c-.42431-.76953-.834-1.585-1.21679-2.42285-3.41846-7.31836-4.86329-15.68847-6.13819-23.07324l-.96-5.56641a3.43688,3.43688,0,0,1,5.41016-3.3623q7.56517,5.50488,15.13623,10.999c1.91113,1.39063,4.09375,3,6.18408,4.73926.20166-.97949.4126-1.96191.62353-2.93066a3.43916,3.43916,0,0,1,6.28077-1.08594l3.88281,6.23828c2.832,4.55566,5.33154,9.04492,4.82226,13.88672a.756.756,0,0,1-.01318.17578,10.9467,10.9467,0,0,1-.56348,2.33105Z" 
        transform="translate(-330.64271 -160.79484)" 
        fill="#f0f0f0"
        />
        <path 
        d="M647.18469,738.89785l-315.3575.30731a1.19069,1.19069,0,0,1,0-2.38134l315.3575-.30732a1.19069,1.19069,0,0,1,0,2.38135Z" 
        transform="translate(-330.64271 -160.79484)" 
        fill="#cacaca"
        />
        </svg>
    )
}